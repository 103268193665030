$red-background: #f8aaaa;
$yellow-background: #ffcb04;
$container-background: #ffffff;
$dark-background: #27282c;
$input-background: #f6f6ec;
$blue-background: #420ee1;

$text: #333333;
$white-text: #ffffff;
$purple-text: #3f0f3f;
$light-color: #bdbdbd;
$error-text: #f44336;

$border-color: rgb(0, 0, 0, 0.1);

$error-input: rgba(255, 72, 72, 0.1);

/* sizes */

$responsive-width: 500px;
$tablet-width: 1024px;
$desktop-width: 1800px;

$border-radius: 12px;

$header-height: 90px;
$footer-height: 110px;

$document-content: 1024px;
