@import 'src/variables';
@import '~mapbox-gl/dist/mapbox-gl.css';
@import '~@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
        font-size: 16px;
    }
}

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    overflow: hidden;

    * {
        caret-color: $text !important;
    }
}

button,
a,
mat-icon {
    outline: none;
    cursor: pointer;

    span {
        white-space: nowrap;
    }
}

button {
    background-color: $yellow-background;
    box-shadow: none;
    border-radius: 12px;
    font-size: 16px;
    width: 100%;
    height: 50px;
    min-height: 40px;

    margin-top: auto;

    span {
        color: $text;
    }
}

input {
    outline: none;
    text-overflow: ellipsis;
    padding-right: 5px !important;
}

input[type='file'] {
    display: none;
}

textarea {
    resize: none;
}

button[disabled] {
    background-color: $input-background !important;
}

mat-dialog-content {
    height: 100%;
    display: flex !important;
    align-items: center;
    flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[number] {
    -moz-appearance: textfield;
}

input:disabled {
    color: $purple-text !important;
}

.timepicker__header {
    background-color: $yellow-background !important;
}

.clock-face__number > span.active {
    background-color: $yellow-background !important;
}

.clock-face__clock-hand {
    background-color: $yellow-background !important;
}

.scroll {
    overflow: auto;
    &__container {
        height: calc(100% - (90px + 110px));
    }
}

.mobile {
    display: none !important;
    @media (max-width: $responsive-width) {
        display: block !important;
    }
}

.desktop {
    display: none;
    @media (max-width: $responsive-width) and (min-width: $responsive-width) {
        display: block;
    }
}

.mat-dialog-container {
    box-shadow: none !important;
    background: #fff !important;
    color: #000 !important;
    overflow: hidden !important;
    font-family: 'Montserrat Regular', sans-serif;
    font-size: 24px;
    border-radius: 20px !important;

    max-height: 90%;
    cursor: initial;

    @media (max-width: $responsive-width) {
        position: absolute;
        bottom: 0;
        height: 80% !important;
        left: 0;

        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
}

.galley-modal {
    height: 90%;
    width: 90%;
    max-width: 90% !important;
}

.modal {
    &__error,
    &__confirm,
    &__warning,
    &__success {
        html {
            overflow-y: hidden;
        }
        .mat-dialog-container {
            @media (max-width: $responsive-width) {
                position: relative;
                border-radius: 20px !important;
            }
        }
    }
    &__search {
        .mat-dialog-container {
            @media (max-width: $responsive-width) {
                height: 60% !important;
            }
        }
    }

    &__body-crop {
        .mat-dialog-container {
            @media (max-width: $responsive-width) {
                height: auto !important;
            }
        }
    }
}

.mat-dialog-actions {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mat-form-field-wrapper {
    padding: 0 !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0 !important;
}

.mat-raised-button {
    box-shadow: none !important;
}

.mat-expansion-indicator::after {
    color: $text !important;
}

.mapboxgl-map {
    z-index: 0;
}
.mapboxgl-popup-content {
    border-radius: $border-radius !important;
    padding: 12px !important;
}

.mapboxgl-popup-close-button {
    display: none;
}

.cdk-global-scrollblock {
    overflow-y: hidden;
}

.clock-face__clock-hand_minute:before {
    border: 4px solid $yellow-background !important;
}

::-webkit-scrollbar-thumb {
    background: $input-background;
    border-radius: 12px;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    overflow-y: scroll;
    background: transparent;
}

@font-face {
    font-family: 'Montserrat Bold';
    font-display: swap;
    src: url(assets/fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat Regular';
    font-display: swap;
    src: url(assets/fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'VisbyCF-DemiBold';
    font-display: swap;
    src: url(assets/fonts/VisbyCF-DemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'VisbyCF-Bold';
    font-display: swap;
    src: url(assets/fonts/VisbyCF-ExtraBold.ttf) format('truetype');
}
